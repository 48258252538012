import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiOutlineAudit } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import {
  FaFileCode,
  FaGlobe,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaShoppingBasket,
} from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { RiPagesLine } from "react-icons/ri";
import Faq from "../components/faq";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import TeamSection from "../components/team-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import ReactPlayer from "react-player";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";

const LocksmithSEO = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      needHelpImage: wpMediaItem(title: { eq: "Locksmith Seo Latest" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      flexImage: wpMediaItem(title: { eq: "Flex MasterLogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      ryanImage: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Locksmith SEO" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Locksmith SEO",
        item: {
          url: `${siteUrl}/locksmith-seo`,
          id: `${siteUrl}/locksmith-seo`,
        },
      },
    ],
  };

  //test
  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/locksmith-seo`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <SeoHero
            page="Locksmith SEO"
            form
            title={`Locksmith \n<span class="text-secondary">SEO</span>`}
            description={
              <span>
                Attract both higher quality and greater volume of potential 
                customers to your locksmith website
              </span>
            }
            buttons={[
              <Button
                className="px-4 py-2 bg-white border-white  w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
                variant="primary"
                href="#form"
              >
                Book a discovery call
              </Button>,
              <Button
                className="px-5 py-2 mt-4 border-white  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
                variant="primary"
                as={Link}
                to="/contact-us"
              >
                Get in touch
              </Button>,
            ]}
          />
        </section>
        {/* <section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center g-5 g-lg-7">
							<Col xs={6} lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.flexofficesolutions.co.uk/"
								>
									<GatsbyImage
										image={
											data.flexImage.gatsbyImage
										}
										alt={data.flexImage.altText}
										class="w-100"
									/>
								</a>
							</Col>
							<Col xs={6} lg={4}>
								<GatsbyImage
									image={
										data.ryanImage.gatsbyImage
									}
									alt={data.ryanImage.altText}
									class="w-100"
								/>
							</Col>
						</Row>
					</Container>
				</section> */}
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="py-5 bg-light-grey py-lg-7">
          <Container>
            <Row className="align-items-center justify-content-between gx-6">
              <Col lg={6} className="">
                <h2 className="mb-5 display-5 text-primary">
                  Rise above your competitors with a website built for SEO
                </h2>

                <p>
                  Over 52% of users on click on the top 3 search results on
                  Google, so if your website isn't ranking at the top of Google
                  you're missing out on <strong>potential customers</strong>{" "}
                  and their business. With RJM Digital we can make sure you don't miss out!
                </p>
                <p>
                  We understand the importance of ranking in the top positions on
                  Google and other search engine result pages, which is why our
                  experienced team of SEO experts will analyse your current <strong>website
                  content</strong>, review other sites, find keywords your customers are
                  using in their searches and implement these into your website to
                  ensure your site will be <strong>visible on Google</strong>{" "}
                  and <strong>outperform</strong> your competitors in the local area.
                </p>
                <p>
                  Being search engine optimised and at the top of the {" "}<strong>
                  search engine results page</strong>{" "} will make it easier for your
                  customers to find you when they're in an emergency and in need
                  of your {" "}<strong>specialist locksmith services</strong>, meaning your business 
                  will ultimately receive more calls, inquiries and jobs.
                </p>
                <p>
                  Don't let your locksmith website get lost in the digital world,
                  book a discovery meeting today to discuss your{" "}
                  <strong>SEO strategy</strong>{" "} and get a free quote!
                </p>

                <Button
                  className="px-4 py-2 mt-4  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  as={Link}
                  to="/seo-audit"
                >
                  Request a SEO audit
                </Button>
              </Col>
              <Col className="pt-5 pt-lg-0" lg={5}>
                <ReactPlayer
                  playsinline
                  loop
                  muted
                  playing
                  className="h-auto w-100"
                  title="video of what search engine optimisation is and how Locksmiths can use it to succeed online"
                  url="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/08/SEO-for-locksmith-websites.mp4"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="results" className="pb-5 bg-white pb-lg-7">
          <Container>
            <Row className="py-5 align-items-center py-xl-0">
              <Col lg={{ span: 8, order: "last" }}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.seoImg.gatsbyImage}
                    alt={data.seoImg.altText}
                  />
                </div>
              </Col>
              <Col lg={4} className=" mt-lg-0">
                <h2 className="mb-4 display-5 text-primary">
                  Proven to deliver
                </h2>
                <p>
                  For <strong>over 2 years</strong> we have worked with locksmiths and have helped
                  them to achieve more than ever before. Even the best locksmiths
                  need a hand unlocking the door to online success. With a helping
                  hand, creating an <strong>SEO strategy</strong> can ultimately help you to{" "}
                  <strong>rank higher</strong> in search engine results pages, increase the amount of
                  traffic you receive, stay relevant by incorporating location based
                  keywords, and gain <strong>greater visibility</strong> to more customers with quality
                  content on your site.
                </p>

                <Button
                  className="px-4 py-2 mt-4  cta-btn w-100 w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  href="#form"
                >
                  Book a discovery call
                </Button>
              </Col>
            </Row>

            <Row className="py-5 align-items-center our-results mt-md-5 py-lg-0 gy-5 gy-lg-0">
              <Col xs={12} className="px-4 text-center py-md-4" lg={true}>
                <div className="p-4 bg-black">
                  <GatsbyImage
                    className="mw-100 "
                    image={data.seymourImg.gatsbyImage}
                    alt={data.seymourImg.altText}
                  />
                </div>
              </Col>
              <Col lg={true}>
                <h2 className="text-center ssp-bold text-lg-start fs-3">
                  Our results:
                </h2>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">5</p>
                <h3 className="fs-5">Locations at #1</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">50%</p>
                <h3 className="fs-5">Increase in CTR</h3>
              </Col>
              <Col className="text-center" lg={true}>
                <p className="pb-1 mb-0 ssp-bold text-primary display-4">2x</p>
                <h3 className="fs-5">More enquiries</h3>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="">
            <Row>
              <Col>
                <h2 className="mb-5 text-white display-2 ssp-bold text-uppercase">
                  Main benefits of
                  <span>
                    <br /> <span className="text-light-blue">SEO</span>
                  </span>
                </h2>
              </Col>
            </Row>
            <Row className="gx-xl-9 g-6 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueOne} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Builds trust</h3>
                  <p className="text-white">
                    Your customers trust google to rank the most suitable
                    websites at the top of the page, therefore ranking high is
                    important.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueTwo} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Increased visibility</h3>
                  <p className="text-white">
                    Being at the top of Google customers will automatically see
                    your business, even if they don’t always click on your site.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueThree} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Better ROI</h3>
                  <p className="text-white">
                    SEO in the long term has a better return on investment as
                    you dont have to pay for each person that clicks onto your
                    site.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className={`mt-5 mt-md-7 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
                  variant="white"
                  as={Link}
                  to="/seo-audit"
                >
                  Get my Free SEO audit
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 bg-light-grey py-lg-7">
          <Container>
            <Row>
              <Col>
                <h2 className="mb-5 display-5 text-primary">Types of SEO</h2>
              </Col>
            </Row>
            <Row className="gy-5">
              <Col md={6} lg={4} className="text-md-center">
                <FaMapMarkerAlt className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">Local SEO</h3>
                <p>
                  Have people in your local area find you on Google when searching
                  for products and services your locksmith business offers.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaShoppingBasket className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">E-Commerce SEO</h3>
                <p>
                  Promote your locksmith products and services by using keywords
                  in your descriptions and watch your website climb to the top
                  of Google with increased web traffic.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaFileCode className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">Technical SEO</h3>
                <p>
                  Improve your user experience by ensuring the technical
                  elements of your site are functioning in the way they should
                  and adhering to Google’s best practices.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaGlobe className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">International SEO</h3>
                <p>
                  Optimise your site so you can be found wherever, whenever and
                  in any language across the globe with just a quick google search.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <FaMobileAlt className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">Mobile SEO</h3>
                <p>
                  Mobile-friendly sites attract typically more customers therefore
                  to improve your website ranking on mobile search results your
                  locksmith website must work perfectly on all devices.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <RiPagesLine className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">On-Page SEO</h3>
                <p>
                  Make the content on your web pages valuable and insightful
                  to your users as well as adhering to Google's best practices.
                  Keyword research is vital to ranking high on search engines.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <BsGraphUp className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">SEO Strategy</h3>
                <p>
                  Have a bespoke and tailored SEO strategy for your locksmith
                  business that delivers you the results and business goals you want to see.
                </p>
              </Col>
              <Col lg={4} className="text-md-center">
                <IoMdAnalytics className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">SEO Analytics</h3>
                <p>
                  Use Google Analytics to understand your websites progress and
                  optimise it so you can continue to improve your results in the future.
                </p>
              </Col>
              <Col md={6} lg={4} className="text-md-center">
                <AiOutlineAudit className="display-5 text-primary" />
                <h3 className="my-4 ssp-bold">SEO Audit</h3>
                <p>
                  Understand what SEO marketing strategies are working well and
                  what else needs to improve to get better results, such as website
                  speed, website responsiveness and organic backlinks.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <WhyChooseRJMSection
          experienced="You need a specialist team to bring you new leads that you demand from your website. At RJM Digital we have over 5 years experience in the industry and have delivered a proven platform for locksmiths."
          bespoke="Each and every one of our websites is different and unique to the individual customer it was made for. We strive to create websites that stand out from the crowd."
          provenResults="Over the years we have continually delivered results for our customers and our testimonials highlight this. Most marketing agencies say they can deliver but don’t have the evidence to back it up."
          reliable="Throughout your whole partnership with RJM Digital, we will always be by your side for any help or assistance needed."
          transparent="At every step of the way we will be open in everything that we do, even if things don’t go as planned. We will always be available for a call or meeting to answer any queries you may have."
          trustworthy="By keeping to our core values of being reliable, transparent and results-driven, we are proud to have built long-lasting relationships with 50+ customers from around the world."
        />

        <section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="mb-3 display-5 text-primary">
                  Book a discovery meeting
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your SEO strategy for your locksmith business.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2sae!4v1673980072743!5m2!1sen!2sae" />
        <section className="my-6">
          <TeamSection />
        </section>
        <div id="faq">
          <Faq page="Real Estate Dubai" />
        </div>
        <OSSection
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        />
      </Layout>
    </>
  );
};

export default LocksmithSEO;
